.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-text {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  padding-top: 20px;
}

.App-link {
  color: #ffffff;
  height: 48px;
  width: 80%;
  background-color: black;
  border-radius: 10px;
  align-self: center;
  line-height: 2;
  text-decoration: none;
  position: absolute;
  bottom:32px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
